import React, { useEffect, useState, useRef, useContext } from "react";

import security from "./assets/security.png";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Helmet } from "react-helmet";

import { UserContext } from "./context/userContext";

import reduction from "./assets/-50.png";

import cartesacceptees from "./assets/cartesacceptees.jpg";

import { CountDownTimer, roundedValueString } from "./Function";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm";

import toast, { Toaster } from "react-hot-toast";

const PUBLIC_KEY =
  "pk_live_51LLVloCctSjPzYrflyIl7nhsMRT2msDe3nDvO1B4dSdLgd9Emjnaj2X9mUFfo4EmYcxfftdv3zXBTxapc7WfA28T00ejEADNan";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const country = require("./country.json");
const cities = require("./cities.json");
const names = require("./names.json");

function Order() {
  let navigate = useNavigate();

  let params = useParams();

  const { state } = useLocation();
  const PIXEL_ID = state?.PIXEL_ID ? state?.PIXEL_ID : "";

  const paymentForm = useRef();

  const [minutesCountdown, secondsCountdown] = CountDownTimer(21, 59);
  const [selectedQuantity, setSelectedQuantity] = useState(5);

  const [stockCount, setStockCount] = useState();

  const [name, setName] = useState("");
  const [surName, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const [errorInput, setErrorInput] = useState({});

  const [dropDownOptionCapacity, setDropDownOptionCapacity] = useState([]);
  const [defaultOption, setDefaultOption] = useState("France");
  const [currentDropdownOption, setCurrentDropdownOption] = useState("France");

  const [loading, setLoading] = useState(false);

  const { item, itemLoading, getItemData, makePayment, confirmPayment } =
    useContext(UserContext);

  useEffect(() => {
    const intervalID = window.setInterval(checkWeatherAPI, 10000);

    var initStockCount = getRandomInt(45, 60);
    setStockCount(initStockCount);

    function checkWeatherAPI() {
      const city = cities[(cities.length * Math.random()) | 0];
      const quantity = getRandomInt(1, 5);
      const name = JSON.parse(
        JSON.stringify(names[(names.length * Math.random()) | 0].prenoms)
      );
      if (initStockCount > 10) {
        initStockCount = initStockCount - quantity;
        setStockCount(initStockCount);

        toast({
          name: name,
          city: city.city,
          region: city.admin_name,
          quantity: quantity,
        });
      }
    }

    return () => {
      window.clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    getData();
    async function getData() {
      const itemId = params.id;
      const itemBuf = await getItemData(itemId);

      document.title = `${itemBuf.name} - Commande`;
    }

    var arrayOfCountry = [];
    Object.values(country).forEach((el) => {
      arrayOfCountry.push(el);
    });
    setDropDownOptionCapacity(arrayOfCountry);
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handlePaymentClick = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      setErrorInput({});

      var errorInputBuf = {};
      if (name == "")
        errorInputBuf = {
          ...errorInputBuf,
          name: "Ce champs n'est pas valide",
        };
      if (surName == "")
        errorInputBuf = {
          ...errorInputBuf,
          surName: "Ce champs n'est pas valide",
        };
      if (email == "" && !email.includes("@") && !email.includes("."))
        errorInputBuf = {
          ...errorInputBuf,
          email: "Ce champs n'est pas valide",
        };
      if (phoneNumber == "")
        errorInputBuf = {
          ...errorInputBuf,
          phoneNumber: "Ce champs n'est pas valide",
        };
      if (address == "")
        errorInputBuf = {
          ...errorInputBuf,
          address: "Ce champs n'est pas valide",
        };
      if (city == "")
        errorInputBuf = {
          ...errorInputBuf,
          city: "Ce champs n'est pas valide",
        };
      if (postalCode == "")
        errorInputBuf = {
          ...errorInputBuf,
          postalCode: "Ce champs n'est pas valide",
        };
      setErrorInput(errorInputBuf);

      if (
        name == "" ||
        surName == "" ||
        email == "" ||
        !email.includes("@") ||
        !email.includes(".") ||
        phoneNumber == "" ||
        address == "" ||
        city == "" ||
        postalCode == "" ||
        !item
      ) {
        setLoading(false);
        return;
      }

      const paymentMethodData = await paymentForm.current.handleSubmit();

      if (!paymentMethodData.paymentMethod?.id) {
        errorInputBuf = {
          ...errorInputBuf,
          card: "Ce champs n'est pas valide",
        };
        setLoading(false);
        setErrorInput(errorInputBuf);
        return;
      }

      const data = (
        await makePayment(
          paymentMethodData.paymentMethod.id,
          item.id,
          selectedQuantity,
          name,
          surName,
          email,
          phoneNumber,
          address,
          city,
          postalCode,
          currentDropdownOption
        )
      ).data;

      if (data?.status == "succeeded") {
        // window.taboolaPaymentDone(
        //   item.priceDetail[selectedQuantity],
        //   selectedQuantity
        // );
        setLoading(false);
        setOpenModal(true);
      } else if (data.paymentRequireActions) {
        //3d secure

        const { error, paymentIntent } =
          await paymentForm.current.handleRequireActions(
            data.paymentRequireActions
          ); //show 3d secure

        if (paymentIntent?.status == "requires_confirmation") {
          const data = (
            await confirmPayment(
              paymentIntent.id,
              item.id,
              selectedQuantity,
              name,
              surName,
              email,
              phoneNumber,
              address,
              city,
              postalCode,
              currentDropdownOption
            )
          ).data;

          if (data?.status == "succeeded") {
            // window.taboolaPaymentDone(
            //   item.priceDetail[selectedQuantity],
            //   selectedQuantity
            // );
            setLoading(false);
            setOpenModal(true);
          } else {
            setLoading(false);
            setErrorInput({
              card: "Le paiement a été decliné et n'a pas été prise en compte, veuillez réessayer",
            });
          }
        } else {
          setLoading(false);
          setErrorInput({
            card: "Le paiement a été decliné et n'a pas été prise en compte, veuillez réessayer",
          });
        }
      } else {
        setLoading(false);
        setErrorInput({
          card: "Le paiement a été decliné et n'a pas été prise en compte, veuillez réessayer",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ backgroundColor: "rgb(245, 245, 245)" }}>
      <Helmet>
        <script type="text/javascript">
          {` window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: ${PIXEL_ID}});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/${PIXEL_ID}/tfa.js',
  'tb_tfa_script');`}
        </script>
        <script>
          {` function taboolaPaymentDone(revenue, quantity) {
      _tfa.push({ notify: 'event', name: 'make_purchase', id:${PIXEL_ID}, 
      revenue, quantity, 
      currency: '€' })
    }`}
        </script>
      </Helmet>
      <header className="header-order new-element" style={{}}>
        {itemLoading == false && (
          <img className="header-second-img" src={item.logo}></img>
        )}
      </header>
      {itemLoading == false && (
        <main className="order-container">
          <article>
            <section className="order-article-photo-description new-element">
              <div className="order-article-photo-container">
                {item.image2 ? (
                  <img className="order-article-photo" src={item.image2}></img>
                ) : (
                  <img className="order-article-photo" src={item.image}></img>
                )}
              </div>
              <div className="order-article-description-container">
                {item.descriptionText.map((el, index) => {
                  return (
                    <div key={index} className="order-article-description">
                      <FontAwesomeIcon
                        style={{ color: "green", marginRight: 10 }}
                        size="lg"
                        icon={["fa", "check"]}
                      />
                      <p
                        dangerouslySetInnerHTML={{ __html: el }}
                        className="order-article-description-text"
                      ></p>
                    </div>
                  );
                })}
              </div>
            </section>

            <section className="new-element">
              <p className="order-special-offer-text">
                <b>OFFRE SPÉCIALE :</b> {item.name} est disponible au prix de{" "}
                <strong className="line-text">
                  {item.priceDetail[1] * 2}€
                </strong>{" "}
                <strong style={{ color: "green" }}>
                  {item.priceDetail[1]}€
                </strong>
                * <b>(50% de réduction par unité)</b>
              </p>
              <p className="order-special-offer-text">
                <b style={{ color: "green" }}>Livraison gratuite</b> sur chaque
                commande !
              </p>
              <p
                className="order-special-offer-text"
                style={{ fontStyle: "italic" }}
              >
                *Sous réserve de disponibilité
              </p>
            </section>

            <section className="order-article-step-description">
              <div className="order-article-step-1-container ">
                {item.enableCounter && (
                  <div className="order-article-step-1-reduction-container new-element">
                    <div className="order-article-step-1-reduction-text-container">
                      <span style={{ fontSize: "1.3em", fontWeight: "600" }}>
                        STOCK RESTANT :
                        <strong style={{ color: "red" }}> {stockCount}</strong>
                      </span>
                    </div>
                  </div>
                )}

                <div className="order-article-step-1-reduction-container">
                  <img
                    className="order-article-step-1-reduction"
                    src={reduction}
                  ></img>
                  <div className="order-article-step-1-reduction-text-container">
                    <FontAwesomeIcon
                      style={{ color: "green", marginRight: 10 }}
                      size="lg"
                      icon={["fa", "circle-check"]}
                    />
                    <span style={{ fontSize: "1.1em", fontWeight: "600" }}>
                      Votre code de réduction de{" "}
                      <strong style={{ color: "red" }}>-50%</strong> a été
                      appliqué
                    </span>
                    <br />
                    {item.enableCounter && (
                      <span style={{ fontSize: "1.1em", fontWeight: "600" }}>
                        <strong style={{ color: "red" }}>ATTENTION</strong>, ce
                        code expire dans :{" "}
                        <strong style={{ color: "red" }}>
                          {minutesCountdown} : {secondsCountdown}
                        </strong>
                      </span>
                    )}
                    <br />
                    <span style={{ fontSize: "1.1em", fontStyle: "italic" }}>
                      Ne fermez pas cette page !
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "1.1em" }}>
                      Livraison gratuite disponible aujourd'hui
                    </span>
                  </div>
                </div>

                <p className="order-article-step-title">
                  Étape 1 : Choisissez la quantité
                </p>

                <div
                  className="order-article-step-1-article-price-most-sold-container new-element"
                  onClick={() => {
                    setSelectedQuantity(5);
                  }}
                >
                  <div className="order-article-step-1-article-price-most-sold">
                    <div className="order-article-step-1-article-price-checkbox-most-sold">
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        size="1x"
                        icon={
                          selectedQuantity == 5
                            ? ["far", "circle-dot"]
                            : ["far", "circle"]
                        }
                      />
                      <label style={{ fontWeight: "500" }}>
                        3 {item.name} achetés{" "}
                        <strong style={{ color: "orange" }}>
                          + 2 gratuits
                        </strong>{" "}
                        ({roundedValueString(item.priceDetail[5] / 5)}€/unité)
                      </label>
                    </div>

                    <div></div>

                    <span style={{ fontWeight: "500" }}>
                      {roundedValueString(item.priceDetail[5])}€
                    </span>
                  </div>
                </div>

                <div
                  className="order-article-step-1-article-price new-element"
                  onClick={() => {
                    setSelectedQuantity(3);
                  }}
                >
                  <div className="order-article-step-1-article-price-checkbox">
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      size="1x"
                      icon={
                        selectedQuantity == 3
                          ? ["far", "circle-dot"]
                          : ["far", "circle"]
                      }
                    />
                    <label>
                      2 {item.name} achetés{" "}
                      <strong style={{ color: "orange" }}>+ 1 gratuit</strong> (
                      {roundedValueString(item.priceDetail[3] / 3)}€/unité)
                    </label>
                  </div>

                  <span>{roundedValueString(item.priceDetail[3])}€</span>
                </div>

                <div
                  className="order-article-step-1-article-price new-element"
                  onClick={() => {
                    setSelectedQuantity(1);
                  }}
                >
                  <div className="order-article-step-1-article-price-checkbox">
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      size="1x"
                      icon={
                        selectedQuantity == 1
                          ? ["far", "circle-dot"]
                          : ["far", "circle"]
                      }
                    />
                    <label>
                      1 {item.name} (
                      {roundedValueString(item.priceDetail[1] / 1)}€/unité)
                    </label>
                  </div>

                  <span>{roundedValueString(item.priceDetail[1])}€</span>
                </div>

                <div
                  className="order-article-step-1-article-price new-element"
                  onClick={() => {
                    setSelectedQuantity(2);
                  }}
                >
                  <div className="order-article-step-1-article-price-checkbox">
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      size="1x"
                      icon={
                        selectedQuantity == 2
                          ? ["far", "circle-dot"]
                          : ["far", "circle"]
                      }
                    />
                    <label>
                      2 {item.name} (
                      {roundedValueString(item.priceDetail[2] / 2)}€/unité)
                    </label>
                  </div>

                  <span>{roundedValueString(item.priceDetail[2])}€</span>
                </div>

                <div
                  className="order-article-step-1-article-price new-element"
                  onClick={() => {
                    setSelectedQuantity(4);
                  }}
                >
                  <div className="order-article-step-1-article-price-checkbox">
                    <FontAwesomeIcon
                      style={{ marginRight: 10 }}
                      size="1x"
                      icon={
                        selectedQuantity == 4
                          ? ["far", "circle-dot"]
                          : ["far", "circle"]
                      }
                    />
                    <label>
                      4 {item.name} (
                      {roundedValueString(item.priceDetail[4] / 4)}€/unité)
                    </label>
                  </div>

                  <span>{roundedValueString(item.priceDetail[4])}€</span>
                </div>

                <p className="order-article-step-title">
                  Étape 2 : Entrez vos informations de contact
                </p>

                <div className="input-double-container new-element">
                  <div className="input-double">
                    <label style={{ color: "gray", fontWeight: "500" }}>
                      Prénom*
                    </label>
                    <input
                      className="full-input"
                      onChange={(e) => {
                        setName(e.currentTarget.value);
                      }}
                      value={name}
                      onClick={() => {}}
                    />
                    <label style={{ color: "red" }}>{errorInput.name}</label>
                  </div>
                  <div className="input-double ">
                    <label style={{ color: "gray", fontWeight: "500" }}>
                      Nom de famille*
                    </label>
                    <input
                      className="full-input"
                      onChange={(e) => {
                        setSurname(e.currentTarget.value);
                      }}
                      value={surName}
                      onClick={() => {}}
                    />
                    <label style={{ color: "red" }}>{errorInput.surName}</label>
                  </div>
                </div>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Adresse e-mail*
                  </label>
                  <input
                    className="full-input"
                    onChange={(e) => {
                      setEmail(e.currentTarget.value);
                    }}
                    value={email}
                    type="email"
                    onClick={() => {}}
                  />
                  <label style={{ color: "red" }}>{errorInput.email}</label>
                </div>

                <div className="input-container ">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Numéro de téléphone (pour le livreur)*
                  </label>
                  <input
                    className="full-input"
                    onChange={(e) => {
                      setPhoneNumber(e.currentTarget.value);
                    }}
                    value={phoneNumber}
                    onClick={() => {}}
                  />
                  <label style={{ color: "red" }}>
                    {errorInput.phoneNumber}
                  </label>
                </div>
              </div>
              <div className="order-article-step-2-container">
                <p
                  style={{ margin: 0, textAlign: "center" }}
                  className="order-article-step-title"
                >
                  {item.name}
                </p>
                <p style={{ marginTop: 0, textAlign: "center" }}>
                  LIVRAISON GRATUITE aujourd'hui
                </p>
                <img className="order-img-item" src={item.image}></img>
                <p
                  style={{ marginTop: 0 }}
                  className="order-article-step-title"
                >
                  Étape 3 : Entrez vos informations de livraison
                </p>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Pays*
                  </label>
                  <Dropdown
                    controlClassName="full-dropdown"
                    onChange={(value) => {
                      setCurrentDropdownOption(Number(value.value));
                    }}
                    options={dropDownOptionCapacity}
                    value={defaultOption}
                    placeholder="Choisir une option"
                  />
                  <label style={{ color: "red" }}>{errorInput.country}</label>
                </div>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Numéro et nom de la voie*
                  </label>
                  <input
                    className="full-input"
                    onChange={(e) => {
                      setAddress(e.currentTarget.value);
                    }}
                    value={address}
                    onClick={() => {}}
                    name="address"
                  />
                  <label style={{ color: "red" }}>{errorInput.address}</label>
                </div>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Ville*
                  </label>
                  <input
                    className="full-input"
                    onChange={(e) => {
                      setCity(e.currentTarget.value);
                    }}
                    value={city}
                    onClick={() => {}}
                  />
                  <label style={{ color: "red" }}>{errorInput.city}</label>
                </div>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Code postal*
                  </label>
                  <input
                    className="full-input"
                    onChange={(e) => {
                      setPostalCode(e.currentTarget.value);
                    }}
                    value={postalCode}
                    onClick={() => {}}
                  />
                  <label style={{ color: "red" }}>
                    {errorInput.postalCode}
                  </label>
                </div>

                <div className="input-container new-element">
                  <label style={{ color: "gray", fontWeight: "500" }}>
                    Carte de paiement*
                  </label>
                  <Elements stripe={stripeTestPromise}>
                    <CheckoutForm ref={paymentForm} />
                  </Elements>
                  <label style={{ color: "red" }}>{errorInput.card}</label>
                </div>

                <img
                  className="order-card-image new-element"
                  src={cartesacceptees}
                ></img>

                <button
                  disabled={loading}
                  id="payment-button"
                  onClick={(e) => {
                    handlePaymentClick(e);
                  }}
                  className="order-article-payment-button"
                >
                  {loading == false
                    ? `OUI ! ENVOYEZ-MOI MON ${item.name.toUpperCase()} MAINTENANT`
                    : "PAIEMENT EN COURS"}
                </button>

                <img className="order-security-image" src={security}></img>

                <p style={{ marginBottom: 0 }}>
                  🔒 Toutes nos transactions sont cryptées et sécurisées
                </p>
              </div>
            </section>
          </article>

          {openModal && (
            <div className="order-popup-container">
              <div className="order-popup">
                <FontAwesomeIcon
                  className="new-element"
                  style={{ color: "green", marginRight: 10 }}
                  size="5x"
                  icon={["fa", "circle-check"]}
                />
                <span
                  style={{ fontWeight: 600, fontSize: "1.2em" }}
                  className="new-element"
                >
                  Votre commande a bien été validé !
                </span>
                <span className="new-element">
                  Un mail de confirmation vous a été envoyé.
                </span>

                <button
                  className="order-confirmation-ok-button"
                  onClick={() => {
                    setOpenModal(false);
                    if (item.id === "purifiair")
                      navigate(`/purifiair-revolution-depollution-de-l-air`);
                    else if (item.id === "vitalsocks")
                      navigate("/vitalsocks-revolution-soin-des-pieds");
                    else if (item.id === "suai")
                      navigate("/suai-revolution-soin-des-pieds");
                    else navigate(`/article-revolutionnaire?id=${item.id}`);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}

          <Toaster
            position="bottom-right"
            toastOptions={{
              duration: 4000,
            }}
          >
            {(t) => {
              return (
                <div
                  style={{
                    opacity: t.visible ? 1 : 0,
                    animation: t.visible
                      ? "custom-enter 0.5s"
                      : "custom-exit 0.5s",
                    backgroundColor: "#fff",
                    padding: 5,
                    paddingInline: 20,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <img
                    style={{}}
                    className="toast-product-img"
                    src={item.image}
                  ></img>
                  <div style={{ maxWidth: 160 }}>
                    <p style={{}}>
                      <b>{t.message.name}</b> vient de commander{" "}
                      <b style={{ color: "orange" }}>
                        {t.message.quantity} {item.name}
                      </b>{" "}
                      depuis <b>{t.message.city}</b> ({t.message.region})
                    </p>
                  </div>
                </div>
              );
            }}
          </Toaster>
        </main>
      )}

      <footer className="order-footer">
        <nav className="order-footer-nav">
          <span className="order-footer-nav-text" style={{}}>
            Mentions légales | CGV | Livraison et Retour
          </span>
          <span className="order-footer-nav-text" style={{}}>
            Tous Droits Réservés
          </span>
        </nav>
      </footer>
    </div>
  );
}

export default Order;
